import { CurrencyTypeEnum, ServerCapacityData, TarifficationPeriodEnum } from 'api/CailagateApi/api/client';
import { t } from 'localization';
import { roundNumberWithPrecision } from 'utils/numbers';

const periodsInSeconds = {
  [TarifficationPeriodEnum.SECOND]: 1,
  [TarifficationPeriodEnum.MINUTE]: 60,
  [TarifficationPeriodEnum.HOUR]: 60 * 60,
  [TarifficationPeriodEnum.DAY]: 24 * 60 * 60,
  [TarifficationPeriodEnum.MONTH]: 30 * 24 * 60 * 60,
  [TarifficationPeriodEnum.YEAR]: 365 * 24 * 60 * 60,
};

const deafultPeriodsToCalculate = [
  TarifficationPeriodEnum.MINUTE,
  TarifficationPeriodEnum.HOUR,
  TarifficationPeriodEnum.DAY,
];

const DECIMAL_PLACES = 2;

export class ServerInfoService {
  static getPricePerSecond(price: number, period: TarifficationPeriodEnum) {
    return price / periodsInSeconds[period];
  }

  static getFullServerPriceLabel({
    period,
    price,
    currencyType = CurrencyTypeEnum.TOKEN,
    periodsToCalculate = deafultPeriodsToCalculate,
  }: {
    period?: TarifficationPeriodEnum;
    price?: number;
    currencyType: CurrencyTypeEnum;
    periodsToCalculate: TarifficationPeriodEnum[];
  }) {
    if (!period) return null;
    if (!price) return t('ServerInfo:PricePerPeriod:Free');

    const pricePerSecond = price / periodsInSeconds[period];

    return periodsToCalculate
      .reduce((label, period) => {
        const price = roundNumberWithPrecision(pricePerSecond * periodsInSeconds[period], DECIMAL_PLACES);
        const labelToAdd = t(`ServerInfo:PricePerPeriod:${currencyType}`, {
          count: price,
          period: t(`ServerInfo:PrcePeriod:${period}`),
        });
        return [...label, labelToAdd];
      }, [] as string[])
      .join(', ');
  }

  static getCapacity(capacity?: ServerCapacityData) {
    return capacity
      ? [
          `CPU: ${capacity.cpu}`,
          `Disk: ${capacity.disk}`,
          !!capacity.gpuModels.length ? `GPU: ${capacity.gpuModels}` : '',
          `RAM: ${capacity.memory}`,
        ]
          .filter(Boolean)
          .join(', ')
      : '';
  }
}
