import { ErrorOption, FieldPath } from 'react-hook-form';
import { parseError } from 'utils';
import { FormSubmitError, isFormError, ParsedFormError } from './types';
import { AxiosError } from 'axios';

export const errorMessageRegExp = /^(?:\$\.)?((?:\w|\.)*)(?:\[.*?)?:\s*(.*?)\s*$/m;

export function parseErrorMessage<FIELD_NAME extends string>(
  errorMessage: string,
  formFieldNames: FIELD_NAME[],
  additionalMessage: string | undefined = undefined
): { message: string; placement: FIELD_NAME } | undefined {
  const match = errorMessage.match(errorMessageRegExp);
  if (!match) return undefined;

  const [, placement, message] = match;
  if (!message || !placement || !formFieldNames.includes(placement as FIELD_NAME)) return undefined;

  return {
    message: additionalMessage ? `${message}\n${additionalMessage}` : message,
    placement: placement as FIELD_NAME,
  };
}

function getCommonError(error: any): [
  {
    message: string;
    placement: 'commonError';
  }
] {
  return [
    {
      message: parseError(error),
      placement: 'commonError',
    },
  ];
}

export function parseFormError<FIELD_NAME extends string>(
  error: any,
  formFieldNames: FIELD_NAME[]
): ParsedFormError<FIELD_NAME>[] {
  if (!isFormError(error?.response?.data)) {
    return getCommonError(error);
  }
  const errorObject: FormSubmitError = error.response.data;
  if (errorObject.errors?.length) {
    const parsedErrors = errorObject.errors.reduce((parsedErrors, errorObject) => {
      const parsedError = parseErrorMessage(errorObject?.message as string, formFieldNames);
      return !parsedError ? parsedErrors : [...parsedErrors, parsedError];
    }, [] as ParsedFormError<FIELD_NAME>[]);
    if (parsedErrors.length) return parsedErrors;
  }
  if (errorObject.message) {
    const parsedFormError = parseErrorMessage(errorObject.message as string, formFieldNames);

    if (parsedFormError) {
      return [parsedFormError];
    }
  }
  return getCommonError(error);
}

export function handleFormErrorsGeneric<FIELD_TYPE extends Record<string, any> & { commonError: any }>(
  error: any,
  formFieldNames: FieldPath<FIELD_TYPE>[],
  setError: (fieldName: FieldPath<FIELD_TYPE> | 'commonError', errorOption: ErrorOption) => void
) {
  const parsedErrors = parseFormError<FieldPath<FIELD_TYPE>>(error, formFieldNames);
  parsedErrors.forEach(({ message, placement }) => {
    setError(placement, {
      message,
    });
  });
}

export function handleFormErrors<FIELD_NAME extends string>(
  error: any,
  formFieldNames: FIELD_NAME[],
  setError: (fieldName: FIELD_NAME | 'commonError', errorOption: ErrorOption) => void
) {
  const parsedErrors = parseFormError<FIELD_NAME>(error, formFieldNames);
  parsedErrors.forEach(({ message, placement }) => {
    setError(placement, {
      message,
    });
  });
}

export function parseAndEnhanceFormError<FIELD_NAME extends string, NEW_FIELD_NAME extends string>(
  error: any,
  formFieldNames: FIELD_NAME[],
  fieldsToChange: Partial<Record<FIELD_NAME, NEW_FIELD_NAME>>,
  setError: (fieldName: any, error: ErrorOption) => void
) {
  if (!isFormError(error?.response?.data)) {
    return getCommonError(error);
  }
  const formErrors = parseFormError(error, formFieldNames);
  const enhancedFormErrors = formErrors.map(error => ({
    ...error,
    placement: fieldsToChange[error.placement as FIELD_NAME] || (error.placement as NEW_FIELD_NAME),
  }));
  enhancedFormErrors.forEach(({ message, placement }) => {
    setError(placement, {
      message,
    });
  });
}

export function buildErrorMessage(placement: string, message: string) {
  return `${placement}: ${message}`;
}

export function enhanceFormError<FIELD_NAME extends string, NEW_FIELD_NAME extends string>(
  data: any,
  formFieldNames: FIELD_NAME[],
  fieldsToChange: Partial<Record<FIELD_NAME, NEW_FIELD_NAME>>
): any {
  if (!isFormError(data)) {
    return data;
  }
  let enhancedData: FormSubmitError = data;
  if (enhancedData.errors?.length) {
    const enhancedErrors = enhancedData.errors.map(error => {
      if (!error?.message) return error;
      const parsedError = parseErrorMessage(error?.message as string, formFieldNames);
      if (!parsedError) return error;
      if (fieldsToChange[parsedError.placement]) {
        const newPlacement = fieldsToChange[parsedError.placement] as string;
        return { ...error, message: buildErrorMessage(newPlacement, parsedError.message) };
      }
      return error;
    });
    enhancedData.errors = enhancedErrors;
  }
  if (enhancedData.message) {
    const parsedError = parseErrorMessage(enhancedData.message as string, formFieldNames);
    if (parsedError && fieldsToChange[parsedError.placement]) {
      const newPlacement = fieldsToChange[parsedError.placement] as string;
      enhancedData.message = buildErrorMessage(newPlacement, parsedError.message);
    }
  }
  return enhancedData;
}

export function enhanceAxiosError<FIELD_NAME extends string, NEW_FIELD_NAME extends string>(
  error: AxiosError,
  formFieldNames: FIELD_NAME[],
  fieldsToChange: Partial<Record<FIELD_NAME, NEW_FIELD_NAME>>
) {
  const modifiedError = Object.assign(new AxiosError(), error);
  if (error?.response?.data && modifiedError.response) {
    modifiedError.response.data = enhanceFormError(error.response.data, formFieldNames, fieldsToChange);
  }
  return modifiedError;
}

export function gatherFieldNames<FormFieldsType extends Record<string, any>>(
  fields: FormFieldsType,
  parentFieldName = ''
): FieldPath<FormFieldsType>[] {
  const fieldNames: string[] = [];

  for (const fieldName in fields) {
    if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
      const fieldValue = fields[fieldName];
      const currentFieldName = parentFieldName ? `${parentFieldName}.${fieldName}` : fieldName;

      if (typeof fieldValue === 'object' && !Array.isArray(fieldValue) && fieldValue !== null) {
        const nestedFieldNames = gatherFieldNames(fieldValue, currentFieldName);
        fieldNames.push(...nestedFieldNames);
      } else {
        fieldNames.push(currentFieldName);
      }
    }
  }

  return fieldNames as FieldPath<FormFieldsType>[];
}
