import React from 'react';

import Button from 'components/Button';
import { ModelInfoData } from 'api/CailagateApi/api/client';
import { t } from 'localization';
import { useServiceByPrototypeModal } from 'modules/Account/components/ServiceByPrototype';

interface PrototypeServiceTestWidgetInterface {
  serviceData: ModelInfoData;
}

const PrototypeServiceTestWidget = ({ serviceData }: PrototypeServiceTestWidgetInterface) => {
  const [ServiceByPrototypeModalNode, openServiceByPrototypeModal] = useServiceByPrototypeModal(serviceData);

  return (
    <>
      <Button
        type='button'
        color='primary'
        onClick={openServiceByPrototypeModal}
        style={{ marginTop: '25px', width: 'max-content', maxWidth: '295px', alignSelf: 'center' }}
        data-test-id='Button:TestPrototypeServiceTestWidget'
      >
        {t('PrototypeServiceTestWidget:CloneAndLaunch')}
      </Button>
      {ServiceByPrototypeModalNode}
    </>
  );
};

export default React.memo(PrototypeServiceTestWidget);
