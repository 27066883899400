import { TaskTypeData } from 'api/CailagateApi/api/client';
import { t } from 'localization';
import { TaskColorsMap } from 'modules/ServicesCatalog/types';
import React, { useMemo } from 'react';

import TagsChip, { TagsChipType } from '../TagsChip';

import styles from './styles.module.scss';
import { useAppContext } from 'contexts/AppContext';

export interface TagsChipContainerProps {
  taskType?: TaskTypeData;
  languages?: string[];
  prototype?: boolean;
  shownLanguagesLimit?: number;
  children?: React.ReactChild;
}

const getColor = (colorName?: string) => {
  if (!!colorName) {
    const color = TaskColorsMap[colorName];
    if (!!color) {
      return color;
    }
  }
  const randomColor = Object.values(TaskColorsMap)[Math.round(Math.random() * Object.values(TaskColorsMap).length)];
  return randomColor;
};
const TagsChipContainer = ({
  taskType,
  languages = [],
  shownLanguagesLimit,
  prototype = false,
  children,
}: TagsChipContainerProps) => {
  const { isTovieMLP } = useAppContext();
  const languagesToShow = shownLanguagesLimit !== undefined ? languages.slice(0, shownLanguagesLimit) : languages;
  const hiddenLanguagesCount = shownLanguagesLimit !== undefined ? languages.length - shownLanguagesLimit : 0;

  const taskTypeColor = useMemo(() => {
    return getColor(taskType?.color);
  }, [taskType]);

  return (
    <div className={styles.tagChipContainer}>
      {!!taskType && taskType.displayName && (
        <TagsChip
          key={taskType.displayName}
          style={{ backgroundColor: taskTypeColor, color: taskTypeColor, borderColor: taskTypeColor }}
          text={taskType.displayName}
          type={TagsChipType.TextTask}
          iconName={taskType?.icon}
          iconSize='sm'
        />
      )}
      {languagesToShow.map(language => (
        <TagsChip
          key={language}
          text={language.toUpperCase()}
          type={TagsChipType.Language}
          dataTestId={`tagChipLanguage-${language.toUpperCase()}`}
        />
      ))}
      {hiddenLanguagesCount > 0 && (
        <TagsChip
          key='hiddenLanguagesCount'
          text={`+${hiddenLanguagesCount}`}
          type={TagsChipType.Language}
          dataTestId='tagChipLanguage-hiddenLanguagesCount'
        />
      )}

      {prototype && (
        <TagsChip
          key='prototype'
          text={t('Catalog:Card:DeployRequired')}
          type={TagsChipType.Prototype}
          iconName='farServer'
          iconSize='sm'
        />
      )}
      {children}
    </div>
  );
};

export default React.memo(TagsChipContainer);
