import { UseFormReturn } from 'react-hook-form';

export enum LaunchOptions {
  ImmediatelyAfterCreation = 'ImmediatelyAfterCreation',
  LaunchManuallyLater = 'LaunchManuallyLater',
}

export type ServiceByPrototypeFormMethodsType = UseFormReturn<ServiceByPrototypeFormFieldsType, any>;
export enum ServiceByPrototypeFormFieldsNames {
  name = 'name',
  server = 'server',
  launch = 'launch',
}

export const ServiceByPrototypeFormFieldsNamesList = [
  ...Object.values(ServiceByPrototypeFormFieldsNames),
  `commonError`,
] as (ServiceByPrototypeFormFieldsNames | `commonError.${string}`)[];

export type ServiceByPrototypeFormFieldsType = {
  [ServiceByPrototypeFormFieldsNames.name]: string;
  [ServiceByPrototypeFormFieldsNames.server]: string | undefined;
  [ServiceByPrototypeFormFieldsNames.launch]: LaunchOptions;
  commonError: any;
};

export const defaultFormValues: ServiceByPrototypeFormFieldsType = {
  name: '',
  server: undefined,
  launch: LaunchOptions.ImmediatelyAfterCreation,
  commonError: undefined,
};
