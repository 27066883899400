import React, { useCallback } from 'react';

import Spinner from 'components/Spinner';
import ServiceByPrototypeForm, { useServiceByPrototypeForm } from './components/ServiceByPrototypeForm';
import Modal from 'components/Modal';

import { usePromisifyComponent } from 'hooks/usePromisifyComponent';

import { t } from 'localization';

import { AppLogger } from 'services/AppLogger';
import { history } from 'App';

import { ModelInfoData } from 'api/CailagateApi/api/client';

import styles from './styles.module.scss';
import { WORKSPACE_BASE_PATH } from 'routes';

interface ServiceByPrototypeModalInterface {
  isOpen: boolean;
  onClose: () => void;
  onResolve: (data?: unknown) => void;
  serviceData: ModelInfoData;
}

const ServiceByPrototypeModal = ({ isOpen, onClose, onResolve, serviceData }: ServiceByPrototypeModalInterface) => {
  const { formMethods, loading, handleSubmit, serverTemplates } = useServiceByPrototypeForm({
    serviceData,
    formExternalDataDeps: [isOpen],
  });

  const onSubmit = useCallback(async () => {
    try {
      const data = await handleSubmit();
      onResolve();
      if (data) {
        history.push(`/${WORKSPACE_BASE_PATH}/model/${data.id.accountId}/${data.id.modelId}`);
        AppLogger.info(
          {
            message: t('ServiceByPrototypeModal:SuccessfullyCloned'),
          },
          { theme: 'dark' }
        );
      }
    } catch (error: any) {
      AppLogger.log({ exception: error });
    }
  }, [handleSubmit, onResolve]);

  return (
    <Modal
      className={styles.serviceByPrototypeModal}
      title={t('ServiceByPrototypeModal:Title', { name: serviceData?.modelName || '' })}
      isOpen={isOpen}
      size='md'
      onClickCloseIcon={onClose}
      onActionClick={onSubmit}
      onCancelClick={onClose}
      disableActionButtonAutoFocus
      buttonSubmitDisabled={loading}
      buttonCancelDisabled={loading}
      buttonActionText={t('ServiceByPrototypeModal:Submit')}
      buttonCancelText={t('ServiceByPrototypeModal:Cancel')}
    >
      <Spinner centered show={loading} />
      <ServiceByPrototypeForm formMethods={formMethods} serverTemplates={serverTemplates} />
    </Modal>
  );
};

export default React.memo(ServiceByPrototypeModal);

export const useServiceByPrototypeModal = (serviceData?: ModelInfoData, onResolve?: (data: unknown) => void) => {
  return usePromisifyComponent(
    (onResolve, onClose, opened) => {
      if (!serviceData) return null;
      return (
        <ServiceByPrototypeModal isOpen={opened} onClose={onClose} onResolve={onResolve} serviceData={serviceData} />
      );
    },
    { onResolve },
    []
  );
};
