import React from 'react';
import cn from 'classnames';
import ModalDefault from '@just-ai/just-ui/dist/ModalDefault';

import styles from './styles.module.scss';

export type ModalInterface = React.ComponentProps<typeof ModalDefault>;

const Modal = ({ children, className, ...props }: ModalInterface) => {
  return (
    <ModalDefault className={cn(styles.modal, className)} {...props}>
      {children}
    </ModalDefault>
  );
};

export default React.memo(Modal);
