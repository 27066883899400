import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import ControlledInput from 'components/ControlledInput';
import ServerSelect from './components/ServerSelect';
import SimpleRadioButtonsGroup from 'components/SimpleRadioButtonsGroup';
import FormContainer from 'modules/Account/components/FormLayout/FormContainer';

import { LaunchOptions, ServiceByPrototypeFormMethodsType } from './types';

import { t } from 'localization';

import { ServerTemplateData } from 'api/CailagateApi/api/client';

import styles from './styles.module.scss';

interface ServiceByPrototypeFormInterface {
  formMethods: ServiceByPrototypeFormMethodsType;
  serverTemplates: ServerTemplateData[];
}

export default function ServiceByPrototypeForm({ formMethods, serverTemplates }: ServiceByPrototypeFormInterface) {
  const {
    control,
    formState: { errors },
  } = formMethods;

  const launchOptions = useMemo(
    () => [
      {
        label: t('ServiceByPrototypeForm:ShouldLaunchImmediatelyOptions:ImmediatelyAfterCreation'),
        name: 'ImmediatelyAfterCreation',
        value: LaunchOptions.ImmediatelyAfterCreation,
      },
      {
        label: t('ServiceByPrototypeForm:ShouldLaunchImmediatelyOptions:LaunchManuallyLater'),
        name: 'LaunchManuallyLater',
        value: LaunchOptions.LaunchManuallyLater,
      },
    ],
    []
  );

  return (
    <FormContainer
      className={styles.serviceByPrototype}
      showError={errors?.commonError}
      errorMessage={errors.commonError?.message}
    >
      <div className={styles.serviceByPrototype__row}>
        <ControlledInput h3Title={t('ServiceByPrototypeForm:name')} name='name' control={control} placeholder='' />
      </div>
      <ServerSelect name='server' control={control} serverTemplates={serverTemplates} />
      <div className={styles.serviceByPrototype__row}>
        <h3>{t('ServiceByPrototypeForm:LaunchTitle')}</h3>
        <Controller
          name='launch'
          control={control}
          render={({ field: { onChange, value } }) => (
            <SimpleRadioButtonsGroup
              containerClassName='mt-0'
              name='launch'
              value={value || ''}
              onChange={onChange}
              list={launchOptions}
            />
          )}
        />
      </div>
    </FormContainer>
  );
}

export { useServiceByPrototypeForm } from './hooks';
