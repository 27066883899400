import React, { useMemo } from 'react';
import cn from 'classnames';
import { RadioButton } from '@just-ai/just-ui/dist';

import { AvailabilityEnum, ServerTemplateData, TarifficationPeriodEnum } from 'api/CailagateApi/api/client';
import { ServerInfoService } from 'services/ServerInfoService';
import { Control, Path, PathValue, RegisterOptions, UnpackNestedValue, useController } from 'react-hook-form';
import { t } from 'localization';

import styles from './styles.module.scss';
import { useAppContext } from 'contexts/AppContext';

interface RadioButtonInterface {
  name: string;
  value?: string;
  label?: string;
  dataTestId?: string;
  id?: string;
  disabled?: boolean;
}

type ServerDataType =
  | Pick<ServerTemplateData, 'name' | 'id' | 'capacity' | 'tarifficationPeriod' | 'tarifficationPrice'> &
      Pick<RadioButtonInterface, 'value' | 'label' | 'disabled'>;

export interface ServerSelectInterface<FIELD_TYPE extends Record<string, any>> {
  name: Path<FIELD_TYPE>;

  control: Control<FIELD_TYPE>;
  serverTemplates: ServerTemplateData[];
  title?: string;
  className?: string;
  dataTestIdPrefix?: string;
  disabled?: boolean;
  defaultValue?: UnpackNestedValue<PathValue<FIELD_TYPE, Path<FIELD_TYPE>>>;
  rules?: RegisterOptions;
}

const ServerSelect = <FIELD_TYPE extends Record<string, any>>({
  name,
  control,
  serverTemplates,
  className,
  disabled = false,
  dataTestIdPrefix = '',
  defaultValue,
  rules,
}: ServerSelectInterface<FIELD_TYPE>) => {
  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({ name, control, defaultValue, rules });

  const { currencyType } = useAppContext();

  const serverTemplatesList: ServerDataType[] = useMemo(
    () =>
      serverTemplates.map(template => ({
        id: template.id,
        name: template.name,
        tarifficationPeriod: template.tarifficationPeriod,
        tarifficationPrice: template.tarifficationPrice,
        capacity: template.capacity,
        disabled: template.availability === AvailabilityEnum.UNAVAILABLE,
        label: template.name,
        value: template.id.toString(),
      })),
    [serverTemplates]
  );

  if (!serverTemplatesList.length) return null;

  return (
    <div
      className={cn(
        'cmp-radioButtons',
        // 'cmp-simpleRadioButtons',
        {
          disabled,
        },
        className
      )}
    >
      <h3>
        <b>{t('ServiceByPrototypeForm:ServerTemplateSelect:ToLaunchService')}</b>
      </h3>
      <span>{t('ServiceByPrototypeForm:ServerTemplateSelect:AvailableOptions')}</span>

      <div className={styles.serverSelect__container}>
        <table className={styles.serverSelect__table}>
          <tbody>
            {serverTemplatesList.map(item => (
              <React.Fragment key={item.name || ''}>
                <tr
                  key={`${item.id}${item.name}`}
                  className={cn(
                    value === item.value && styles.serverSelect__row_active,
                    item.disabled && styles.serverSelect__row_disabled
                  )}
                  onClick={event => {
                    event?.preventDefault();
                    !item.disabled && onChange(item?.value);
                  }}
                >
                  <td key={`${item.id}${item.name}.value`}>
                    <div>
                      <RadioButton
                        name={item.name}
                        data-test-id={`${dataTestIdPrefix}${item.name}`}
                        label={item?.label || item.name}
                        id={item?.id || item.name}
                        value={item.value}
                        checked={value === item.value}
                        onChange={() => onChange(item?.value)}
                        disabled={disabled || item.disabled}
                      />
                    </div>
                  </td>
                  <td key={`${item.id}${item.name}.capacity`}>
                    <div>{ServerInfoService.getCapacity(item.capacity)}</div>
                  </td>
                  <td key={`${item.id}${item.name}.price`}>
                    <div>
                      {ServerInfoService.getFullServerPriceLabel({
                        period: item.tarifficationPeriod,
                        price: item.tarifficationPrice,
                        periodsToCalculate: [TarifficationPeriodEnum.HOUR, TarifficationPeriodEnum.MONTH],
                        currencyType,
                      })}
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {invalid && <span className={styles.serverSelect__error}>{error?.message || 'error'}</span>}
      </div>
    </div>
  );
};

export default React.memo(ServerSelect) as typeof ServerSelect;
