import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  defaultFormValues,
  LaunchOptions,
  ServiceByPrototypeFormFieldsNames,
  ServiceByPrototypeFormFieldsNamesList,
  ServiceByPrototypeFormFieldsType,
} from '../types';

import { useDefaultForm } from 'utils/form/hooks';
import { useAuthContext } from 'contexts/AuthContext';

import { handleFormErrors } from 'utils/form';
import { useDI } from 'contexts/AppContext';
import { ResourceGroupServersApiService } from 'services/ApiServices/ResourceGroupServersApiService';
import { AvailabilityEnum, ModelInfoData, ServerTemplateData } from 'api/CailagateApi/api/client';
import { AppLogger } from 'services/AppLogger';
import usePromiseProcessing from 'utils/hooks/usePromiseProcessing';
import { ModelApiService } from 'services/ApiServices/ModelApiService';
import { parseIntOrUndefined } from 'utils';

const TEMPLATES_COUNT = 5;

export const useServiceByPrototypeForm = ({
  serviceData,
  formExternalDataDeps = [],
}: {
  serviceData: ModelInfoData;
  formExternalDataDeps?: any[];
}): {
  formMethods: UseFormReturn<ServiceByPrototypeFormFieldsType, any>;
  loading: boolean;
  serverTemplates: ServerTemplateData[];
  handleSubmit: () => Promise<ModelInfoData | undefined>;
} => {
  const resourceGroupServersApi = useDI(ResourceGroupServersApiService);
  const modelApi = useDI(ModelApiService);

  const { user } = useAuthContext();

  const formMethods = useDefaultForm<ServiceByPrototypeFormFieldsType>({ ...defaultFormValues });

  const [{ loading: loadingServerTemplates, result: serverTemplates }, getServerTemplates] = usePromiseProcessing(
    async () => {
      if (!user) return;
      const { data } = await resourceGroupServersApi.getPrototypeTemplates(
        serviceData.id?.accountId?.toString(),
        serviceData.id?.modelId?.toString(),
        TEMPLATES_COUNT
      );

      !!data.length && formMethods.resetField('server', { defaultValue: data[0]?.id.toString() });
      return data;
    },
    [formMethods, resourceGroupServersApi, serviceData.id?.modelId, user],
    { onError: error => AppLogger.error({ exception: error }) }
  );

  useEffect(() => {
    (async () => {
      const data = await getServerTemplates();
      const server =
        data?.find(item => item.availability !== AvailabilityEnum.UNAVAILABLE)?.id.toString() ||
        defaultFormValues[ServiceByPrototypeFormFieldsNames.server];

      formMethods.reset({
        ...defaultFormValues,
        name: serviceData?.modelName || defaultFormValues[ServiceByPrototypeFormFieldsNames.name],
        server,
      });
    })();
  }, [formMethods, getServerTemplates, serviceData?.modelName, ...formExternalDataDeps]);

  const [{ loading }, handleSubmit] = usePromiseProcessing(
    async () => {
      if (!user) return;

      const { name, server, launch } = formMethods.getValues();
      const {
        id: { modelId, accountId },
      } = serviceData;
      const { data } = await modelApi.createClonedModel(
        accountId.toString(),
        modelId.toString(),
        name,
        launch === LaunchOptions.ImmediatelyAfterCreation,
        server ? parseIntOrUndefined(server) : undefined
      );
      return data;
    },
    [formMethods, modelApi, serviceData, user],
    {
      onError: error => handleFormErrors(error, ServiceByPrototypeFormFieldsNamesList, formMethods.setError),
      throwOnError: true,
    }
  );

  return {
    loading: loading || loadingServerTemplates,
    handleSubmit,
    formMethods,
    serverTemplates: serverTemplates || [],
  };
};
