import React, { ClipboardEventHandler, useCallback } from 'react';

import { Icon } from '@just-ai/just-ui/dist/Icon';
import TagsChipContainer from 'components/TagsChipContainer';
import Button from 'components/Button';
import Chip from 'components/Chip';

import { ModelInfoData } from 'api/CailagateApi/api/client';
import { useServicesContext } from 'contexts/ServicesContext';

import { t } from 'localization';
import { copyToClipboard } from 'utils/copyToClipboard';
import { notifyCommonMessage } from 'components/MessageContainer';

import styles from './styles.module.scss';
import { AppLogger } from 'services/AppLogger';

interface HeaderInterface {
  service: ModelInfoData;
  toggleFavourite: () => Promise<void>;
  showToggleFavorite?: boolean;
}
const Header = function ({ service, toggleFavourite, showToggleFavorite = true }: HeaderInterface) {
  const { modelAccountDisplayName, modelAccountName, modelName, id, languages, favorite, shortDescription } = service;
  const { getTaskTypeData } = useServicesContext();
  const taskTypeObject = getTaskTypeData(service.taskType);

  const handleToggle = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      await toggleFavourite();
    },
    [toggleFavourite]
  );

  const ownerName = modelAccountDisplayName || modelAccountName || id.accountId.toString();

  const onCopy = useCallback((textToCopy: string) => {
    if (!textToCopy) return;
    copyToClipboard(textToCopy).then(() => notifyCommonMessage(t('toast:TextCopiedToClipboard'), { theme: 'dark' }));
  }, []);

  const copyTitle: ClipboardEventHandler<HTMLHeadingElement> = event => {
    try {
      event.preventDefault();
      const textToCopy = modelName.trim();
      event.clipboardData?.setData('text/plain', textToCopy);
    } catch (error) {
      AppLogger.log({ exception: error });
    }
  };

  return (
    <div className={styles.header}>
      <h1 data-test-id={`servicePageTitle-${modelName}`} onCopy={copyTitle}>
        {modelName}
      </h1>
      <div className={styles.header__infoPanel}>
        <TagsChipContainer taskType={taskTypeObject} languages={languages}>
          <Chip
            className={styles.header__accountChip}
            text={ownerName}
            onClick={() => onCopy(id.accountId.toString())}
            clickable
            iconName='farUser'
          />
        </TagsChipContainer>
        {showToggleFavorite && (
          <Button className={styles.header__favoriteButton} onClick={handleToggle}>
            <Icon
              className={styles.header__favoriteIcon}
              data-test-id='ServicePage:ToggleFavorite'
              name={favorite ? 'faStar' : 'farStar'}
            />
            <span>{!favorite ? t('ServicePage:AddToFavorites') : t('ServicePage:RemoveFromFavorites')}</span>
          </Button>
        )}
      </div>
      <p className={styles.header__description}>{shortDescription}</p>
    </div>
  );
};

export default React.memo(Header);
