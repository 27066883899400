import React, { useCallback } from 'react';
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import ErrorFallback from 'components/ErrorFallback';

import { AppLogger } from 'services/AppLogger';

type ErrorFallbackProps = {
  resetErrorBoundary: () => void;
};

export const GlobalErrorFallback = ({ resetErrorBoundary }: ErrorFallbackProps) => {
  return <ErrorFallback onRetry={resetErrorBoundary} />;
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  prefix?: string;
  fallback?: React.ComponentType<FallbackProps>;
}

const ErrorBoundary = ({ prefix, children, fallback = GlobalErrorFallback }: ErrorBoundaryProps) => {
  const onError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      const message = prefix ? `${prefix} => ${error.message}` : `${error.message}`;
      AppLogger.fatal(
        {
          message: message,
          exception: error,
        },
        info
      );
    },
    [prefix]
  );

  if (children === undefined) return null;
  return (
    <ReactErrorBoundary FallbackComponent={fallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export function withErrorBoundary<PROPS extends {}>(
  Cmp: React.ComponentType<PROPS>,
  prefix?: string,
  fallback?: React.ComponentType<FallbackProps>
) {
  return (props: PROPS) => {
    return (
      <ErrorBoundary prefix={prefix} fallback={fallback}>
        <Cmp {...(props as PROPS)} />
      </ErrorBoundary>
    );
  };
}

export default ErrorBoundary;
